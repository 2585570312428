import { A, type AnchorProps } from "@solidjs/router";
import { splitProps } from "solid-js";

import { usePlayer } from "~/components/PlayerContext";
import { isExternalLink } from "~/utils/isExternalLink";

export default function SmartA(props: AnchorProps) {
  const player = usePlayer();
  const isPlaying = () => player.state === "play";

  const [split, rest] = splitProps(props, ["rel", "target"]);

  const rel = () => {
    const relSet = new Set<string>();

    for (const item of split.rel?.trim().split(/ +/g) ?? []) {
      relSet.add(item);
    }

    if (isExternalLink(rest.href)) {
      relSet.add("external");

      if (isPlaying()) {
        relSet.add("noopener");
        relSet.add("noreferrer");
      }
    }

    return relSet;
  };

  return (
    <A
      rel={[...rel()].join(" ") || undefined}
      target={rel().has("external") && isPlaying() ? "_blank" : split.target}
      {...rest}
    />
  );
}
